import React, { useEffect, useState } from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";
import { useBodyClass } from "../../utils/bodyClassUtils";
import ReactImageGallery from "react-image-gallery";
import { add, endOfYear, format, startOfDay } from "date-fns";
import { apiKey, catalogueId, host } from "../../settings";
import { environment } from "../../environments/environment";
import { Link } from "gatsby";

const ShipDetail = ({ shipData, products, shippingCompanyId }) => {
  const [isImageGalleryActive, setIsImageGalleryActive] = useState(false);
  const [showProductButton, setShowProductButton] = useState(false);
  const [images, setImages] = useState([]);
  useBodyClass(isImageGalleryActive, "has-overlay");

  const handleScreenchange = () => {
    setIsImageGalleryActive(!isImageGalleryActive);
  };

  const dateFormat = "yyyy/MM/dd";

  const fetchProducts = () => {
    const selectedMinDate = startOfDay(new Date());
    const selectedMaxDate = endOfYear(add(new Date(), { years: 2 }));

    const searchRequest = {
      officeId: environment.builds[0].officeId,
      payload: {        
        catalogueIds: [ catalogueId ],
        rooms: [
          {
            index: 0,
            pax: [
              {
                age: 25
              },
              {
                age: 25
              }
            ]
          }
        ],
        serviceType: 11,
        searchType: 1,
        fromDate: format(selectedMinDate, dateFormat),
        toDate: format(selectedMaxDate, dateFormat),
        productIds: products.nodes
          .filter(p => p.content?.general?.product?.tideId)
          .map(p => p.content.general.product.tideId)
      }
    }

    /*const availableProductsRequest = {
      catalogueId: catalogueId,
      language: "nl-BE",
      officeId: environment.builds[0].officeId,
      agentId: 0,
      from: format(selectedMinDate, dateFormat),
      to: format(selectedMaxDate, dateFormat),
      calculatePrices: false,
      productType: "Cruise",
      productIds: products.nodes
        .filter(p => p.content?.general?.product?.tideId)
        .map(p => p.content.general.product.tideId),
      periodOffset: 0,
      duration: {
        min: 7,
        max: 60,
      },
      flightsIncluded: false,
      ignorePreferredHotels: false,
      travelerGroupings: [
        {
          travelerAges: [25, 25],
        },
      ],
    };*/

    const url = `${host}/api/web/booking/v2/search`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Api-Key": apiKey,
        "Content-Type": "application/json",
        "Language": "nl-BE"
      },
      body: JSON.stringify(searchRequest),
    });
  };

  useEffect(() => {
    (async () => {
      if (products && products.nodes.length) {
        const productsResponse = await fetchProducts();
        const foundProducts = await productsResponse.json();
        if (foundProducts && foundProducts.length) {
          setShowProductButton(true);
        }
      }
    })();
  }, [products]);

  useEffect(() => {
    if (shipData.content?.general?.imageList) {
      setImages(
        shipData.content.general.imageList.map(image => {
          return { original: addWidthParameter(image.url, 1350), thumbnail: addWidthParameter(image.url, 200) };
        })
      );
    }
  }, [shipData]);

  return (
    <section className="ship-detail">
      <div className="ship-detail__content">
        <div className="ship-detail__image">
          <ReactImageGallery
            items={images}
            useBrowserFullscreen={false}
            onScreenChange={handleScreenchange}
            showPlayButton={false}
          />
        </div>
        <div className="ship-detail__info">
          <h1 className="ship-detail__title">{shipData.content.general.name}</h1>
          <div className="ship-detail__data">
            <div className="ship-detail__data-item">
              <strong>Rederij</strong>
              <p>{shipData.content.general.shippingCompany?.name}</p>
            </div>
            <div className="ship-detail__data-item">
              <strong>Bouwjaar</strong>
              <p>{shipData.content.general.yearManufactured}</p>
            </div>
            <div className="ship-detail__data-item">
              <strong>Aantal sterren</strong>
              <p>{shipData.content.general.stars}</p>
            </div>
            <div className="ship-detail__data-item">
              <strong>Passagiers</strong>
              <p>{shipData.content.general.passengers}</p>
            </div>
          </div>
          <div className="ship-detail__description">
            <div
              className="ship-detail__description-text"
              dangerouslySetInnerHTML={{ __html: shipData.content.general.description }}
            ></div>
          </div>
          {showProductButton && shippingCompanyId && (
            <div className="ship-detail__actions">
                <div className="ship-detail__cta">
                  <Link
                    to={`${environment.builds[0].url}products?shippingCompany=${shippingCompanyId}`}
                    target="_blank"
                  >
                    Toon reizen van deze rederij
                  </Link>
                </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ShipDetail;
