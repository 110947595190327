import { Helmet } from "react-helmet";
import Layout from "../layout";
import React, { useContext } from "react";
import SeoHeaders from "../../components/seo/seo-headers";
import { websiteName } from "../../settings";
import { graphql } from "gatsby";
import GlobalContext from "../../contexts/global-provider";
import ShipDetail from "../../components/ship/detail";

const ShipWildcard = ({ pageContext: { page, ship, navigation, cruiseTypes, shippingCompanyId, privacy }, data }) => {
  const { setNavigation, setPrivacy, theme } = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);

  if (!ship) {
    return <></>;
  }

  // Output

  return (
    <Layout
      isTiny={true}
      showQsm={Boolean(page.content.layout.qsm)}
      isTinted={true}
      cruiseTypes={cruiseTypes}
    >
      <SeoHeaders
        seo={ship.content.seo}
        pageTitle={ship.content.seo && ship.content.seo.title ? ship.content.seo.title : page.content.general.title}
        pagePath={page.content.general.path}
        siteName={websiteName[theme]}
      />
      <Helmet>
        <title>
          {ship.content.seo && ship.content.seo.title ? ship.content.seo.title : page.content.general.title}
        </title>
      </Helmet>
      <ShipDetail shipData={ship} products={data.products} shippingCompanyId={shippingCompanyId}></ShipDetail>      
    </Layout>
  );
};

export const query = graphql`
  query ShipWildcardQuery($shippingCompanyId: Int) {
    products: allTideItemForCruise(
      filter: { content: { general: { shippingCompany: { itemId: { eq: $shippingCompanyId } } } } }
    ) {
      nodes {
        content {
          general {
            product {
              tideId
            }
          }
        }
      }
    }
  }
`;

export default ShipWildcard;
